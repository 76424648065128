import React, { Component } from 'react';
import './App.css';
import { Navbar, Nav } from 'react-bootstrap';

class Menu extends Component {
    render() {
        return (
            <Navbar className="navbar fixed-top navbar-expand-lg nav-bar fixed-top" expand="lg">
                <div className="container">
                    <img className="heightlogo" src={require('./GegeesNavLogo.png')} alt="" />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link className="nav-link btn nav-btn text-color" href="/Home">Home</Nav.Link>
                            <Nav.Link className="nav-link btn nav-btn text-color" href="/Services">Services</Nav.Link>
                            <Nav.Link className="nav-link btn nav-btn text-color" href="/Products">Products</Nav.Link>
                            <Nav.Link className="nav-link btn nav-btn text-color" href="/About">About</Nav.Link>
                            <Nav.Link className="nav-link btn nav-btn text-color" href="/Contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        )
    }
}


export default Menu;