import React, { Component } from 'react';
import './App.css';
import Menu from './Menu';
import Footer from './Footer';
import Time from './Time';
import biopic from './biopic.jpg';

class About extends Component {
    render() {
        return (
            <div className="bg">
                <div className="">
                    <Menu />
                </div>
                <div className="container mt text-color mb-35">
                    <div className="row">
                        <div className="col-8 pb">
                            <ul className="mt-4 NoDot">
                                <h2>About Gegee's</h2>
                                <h3>We are Commited To our Services's</h3>
                                <li className="pb-4"><strong>Gegee’s Studio  is dedicated to service and value.  All of our licensed specialists sincerely enjoy what we do and it is reflective on the smiles and repeat business of our clients.  We promise individual attention and great value for all of our customers.</strong></li>
                                <h3>Appointments</h3>
                                <li className="pb-4"><strong>Please arrive 10 minutes prior to your appointment. We schedule appointments according to our specialists’ availability and specialty. If you are running late, please let us know and we will do our best to accommodate you.  Arriving late will likely limit the time allocated for your service; it will lessen the effectiveness and most importantly your pleasure and enjoyment.  As a courtesy to all clients our treatments are completed as scheduled. Cancellations of all spa services require a 24-hour notice. Thank you for your consideration and understanding.  Gratuities are not included in the price of services and are greatly appreciated.  Suggestions are always welcome.</strong></li>
                                <h3>Gift Certificates</h3>
                                <li className="pb-4"><strong>Ideal for holiday gifts, birthdays, anniversaries as well as employees and business clients.   Gift certificates can be purchased in studio or by calling (386) 365-2134.</strong></li>
                                <img className="border border-dark" src={require('./biopic.jpg')} alt="" />
                            </ul>
                        </div>
                        <Time />
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default About;