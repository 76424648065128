import React, { Component } from 'react';
import TimeContent from './TimeContent';
class Time extends Component {
    render() {
        return (
            <div className="col-lg-4 mb-4 pt-4">
                <TimeContent />
            </div>
        )
    }
}


export default Time;