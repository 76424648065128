import React, { Component } from 'react';

class TimeContent extends Component {
    render() {
        return (
           
            <div className="card">
                <h4 className="card-header font-weight-bold">Open Time:</h4>
                <div className="card-body">
                    <div>
                        <h4 className="font-weight-bold">Appointments Preferred</h4>
                        <p className="font-weight-bold">Monday 10 AM - 6 PM
                        <br />Tuesday 10 AM - 6
                        <br />Wednesday 10 AM - 6
                        <br />Thursday 10 AM - 6
                        <br />Friday 10 AM - 6
                        <br />Saturday By Appointment
                        <br />Sunday Closed</p>
                        <h6 className="font-weight-bold">Walk Ins Welcome during normal hours</h6>
                    </div>
                    {/* <div className="pt-3"> */}
                        {/* <a className="btn btn-md btn-secondary btn-block btn-color" target="_blank" href="https://app.acuityscheduling.com/schedule.php?owner=17062335">Set Appointment Now!</a>
                    </div> */}
                </div>
                <div className="card-footer">
                </div>
            </div>
           
        )
    }
}


export default TimeContent;