import React, { Component } from 'react';
import './App.css';
import ImageGallery from './ImageGallery';
import Menu from './Menu';
import Footer from './Footer';
import Time from './Time';

class App extends Component {
  render() {
    return (
      <div className="bg">
        <div className="mt">
          <Menu />
        </div>
        <div className="container">
          <header className="ImageGallery">
            <ImageGallery />
          </header>
          <div className="container">
            <h1 className="d-flex justify-content-center pt-2 pb-2">Welcome to Gegee's Studio Salon & Day Spa</h1>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col-8 hometext">
                <h2>Gegee's Studio</h2>
                <h4><strong>We are Gegee's Studio - "Where it Begins with Personal"</strong></h4>
                <ul>
                  <li className="ff"><strong>Skin Care</strong></li>
                  <li className="ff"><strong>Spa Packages</strong></li>
                  <li className="ff"><strong>Makeup</strong></li>
                  <li className="ff"><strong>Hair Removal</strong></li>
                  <li className="ff"><strong>Massage</strong></li>
                  <li className="ff"><strong>Manicure & Pedicure</strong></li>
                  <li className="ff"><strong>Hair Care</strong></li>
                </ul>
                <h5 className="Homeinfo"><strong>Treat yourself at GeGee’s Studio & Day Spa</strong></h5>
              </div>
              {/* <div className="col-4">
                <img className="img-fluid rounded" src={require('./makeupbrush.jpg')} alt="" />
              </div> */}
              <Time />
            </div>
            </div>
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
        );
      }
    }
    
    export default App;
