import React, { Component } from 'react';
import './App.css';
import About from './About';
import Home from './Home';
import Menu from './Menu';
import Services from './Services';
import Products from './Products';
import Contact from './Contact';
import Appointment from './Appointment';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIgloo } from '@fortawesome/free-solid-svg-icons'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Memberships from './Memberships';
library.add(faIgloo)

function App() {
  return (
    <Router>
      <div className="vh-100 vw-100 flex flex-column">
        <Menu />
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/Services" component={Services} />
          <Route exact path="/" component={Home} />
          <Route path="/Products" component={Products} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Appointment" component={Appointment} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}


export default App;