import React, { Component } from 'react';
import './App.css';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import Menu from './Menu';
import Footer from './Footer';
import TimeContent from './TimeContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class Services extends Component {
    render() {
        return (
            <div className="bg">
                <div>
                    <Menu />
                </div>
                <div className="container mt">
                    <div className="row">
                        <div className="col-8">
                            <row>
                                <div className="col">
                                    <ul className="mt-4 NoDot">
                                        <h2>Services</h2>
                                        <h3><a id="SkinCare">Skin Care (Facial)</a></h3>
                                        <li className="pb-4"><strong>Hydro Microdermadrasion/<br />Oxygen Infusion</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item1" style={{ marginTop: '-1rem', float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item1">
                                                <card>
                                                    <CardBody>
                                                        This treatment  is a combination of two services: Microdermabrasion and H2O Treatment. Relaxing cleansing facial finished with an LED light treatment. Has lasting results for up to a full week. Rejuvenates dull skin, aging skin, reduction of fine lines and wrinkles.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse>
                                        </li>
                                        <li className="pb-4"><strong>MD Crystal Microdermadrasion</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item2" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item2">
                                                <card>
                                                    <CardBody>
                                                        Traditional MD Crystal Microdermabrasion is a non-invasive treatment that gently exfoliates the top layer of skin. Lightens skin tone, reduces acne by clearing away dead skin cells and impurities. Increases blood and lymphatic circulation deep within the skin, improves skin elasticity and softens fine lines.
                                            </CardBody>
                                                </card>
                                            </UncontrolledCollapse>
                                        </li>
                                        <li className="pb-4"><strong>Vital C Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item3" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item3">
                                                <card>
                                                    <CardBody>
                                                        Supercharged antioxidant complex brightens the skin, improves elasticity and combats the visible signs of aging. Refreshing and hydrating.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Rosacea Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item4" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item4">
                                                <card>
                                                    <CardBody>
                                                    Focuses on healing and decongesting, providing antibacterial, anti-irritants and antioxidant therapy. Gentle application with mild cleanser combined with LED light application, deeply cleans, exfoliates, and hydrates sensitive or irritated skin.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Acne Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item5" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item5">
                                                <card>
                                                    <CardBody>
                                                        An antibacterial, soothing and calming facial for irritated skin. Designed to treat the root cause of acne with minimal side effects. Exfoliation and clearing of clogged pores. Includes treatment program to aid in improvement and healing.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Beauty Flash Lifting Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item6" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item6">
                                                <card>
                                                    <CardBody>
                                                        Designed to visibly reduce fine lines and wrinkles. Helps prevent premature aging and helps to achieve visibly smoother and more youthful appearance by tightening skin and closing pores.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>H2O Facial Treatment/Red Carpet</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item7" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item7">
                                                <card>
                                                    <CardBody>
                                                        Oxygenated treatment that is includes 5-6 steps which radiates the skin and when oxygen introduced to the skin, creates an even, more radiant glow.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Teen Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item8" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item8">
                                                <card>
                                                    <CardBody>
                                                        Customized cleansing routine tailored to the complexion needs of a young adult. Addresses breakouts and includes application with instruction stressing the importance of preemptive skincare.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Head, Neck & Temple Massage</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item10" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item10">
                                                <card>
                                                    <CardBody>
                                                        30 minute cranial massage provides total relaxation for the head and neck. Includes pressure on the temple areas to relieve headaches, de-stress and stimulate blood flow.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Men's Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item12" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item12">
                                                <card>
                                                    <CardBody>
                                                        Customized facial for gentlemen. Includes blemish removal, exfoliation and muscle tone stimulation thru de-stressing.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse>
                                        </li>
                                    
                                        <li className="pb-4"><strong>L.E.D. Light Therapy</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item13" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item13">
                                                <card>
                                                    <CardBody>
                                                        LED light treatment is proven to penetrate deep within the skin to lighten melanin, evens skin tone, promotes collagen and treats mild to moderate acne. It is a painless, relaxing, non-invasive skin care treatment.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Paraffin Mask Treatment</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item14" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item14">
                                                <card>
                                                    <CardBody>
                                                        Hot paraffin is absorbed deep into the skin while cooling. Paraffin treatment hydrates and softens the skin and helps to reduce fine lines. Client can receive head and neck massage, hand treatment or pedicure during this procedure.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Microchanneling</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item15" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item15">
                                                <card>
                                                    <CardBody>
                                                        Skin rejuvenating procedure that corrects deeper imperfections of the tissue to offer improvement. Triggers new collagen production in the skin. Skin is purposely injured while channeling fillers simultaneously. Clinically proven results. Procedure can only be completed every 30 days to allow the skin to heal between visits. Process completed with inflammation reduction treatment.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>Dermaplaning</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item16" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item16">
                                                <card>
                                                    <CardBody>
                                                        Exfoliation by removal of the top layer of skin to increase cellular turnover and removal of vellus hair. Procedure removes all dead skin cells and facial hair, leaving the surface very smooth and radiant.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>PERK Hydrating Facial</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item17" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item17">
                                                <card>
                                                    <CardBody>
                                                        Gently and deeply exfoliates using serums loaded with antioxidants resulting in a flawless face. Skin is hydrated and primed into a smooth surface for a gorgeous glow.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>PERK Lip Service</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item18" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item18">
                                                <card>
                                                    <CardBody>
                                                        Gently and deeply exfoliates using serums loaded with antioxidants resulting in a flawless face. Skin is hydrated and primed into a smooth surface for a gorgeous glow.
                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        <li className="pb-4"><strong>PERK Eye Service</strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item19" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item19">
                                                <card>
                                                    <CardBody>
                                                        Gently and deeply exfoliates using serums loaded with antioxidants resulting in a flawless face. Skin is hydrated and primed into a smooth surface for a gorgeous glow.
                                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                        
                                        <li className="pb-4"><strong>The Skin Trip/Vi Peels </strong>
                                            <button className="btn btn-sm btn-color pt-1 pb-1 pr-2 pl-2" id="item20" style={{ float: 'right' }}>
                                                Details
                                </button>
                                            <UncontrolledCollapse toggler="#item20">
                                                <card>
                                                    <CardBody>
                                                    Medical grade chemical peels that increases collagen for overall skin health. Long lasting results without downtime!
                                                    </CardBody>
                                                </card>
                                            </UncontrolledCollapse></li>
                                            <li className="pb-4"><strong>Skin Tag/Growth Removal</strong></li>
                                    </ul>
                                </div>
                            </row>
                            <row>
                                <div className="col">
                                    <ul className="mt-2 NoDot">
                                        <h3 className="pt-4"><a id="SpaPackages">Spa Packages</a></h3>
                                        <li className="pb-3"><strong>Day Away Pampering Session</strong></li>
                                        <li className="pb-3"><strong>GeGee’s Signature Facial</strong></li>
                                    </ul>
                                    <ul className="mt-2 NoDot">
                                        <h3 className="pt-4"><a id="MakeUp">Make up</a></h3>
                                        <li className="pb-3"><strong>Makeup Application</strong></li>
                                        <li className="pb-3"><strong>Application with Instruction</strong></li>
                                        <li className="pb-3"><strong>Permanent Makeup</strong></li>
                                        <li className="pb-3"><strong>Microblading</strong></li>
                                    </ul>
                                    <ul className="mt-2 NoDot">
                                        <h3 className="pt-4"><a id="HairRemoval">Hair Removal</a></h3>
                                        <li className="pb-3"><strong>Brow, Lip & Chin</strong></li>
                                        <li className="pb-3"><strong>Underarms</strong></li>
                                        <li className="pb-3"><strong>Bikini</strong></li>
                                    </ul>
                                    <ul className="mt-2 NoDot">
                                        <h3 className="pt-4"><a id="Massages">Massage</a></h3>
                                        <li className="pb-3"><strong>One Hour</strong></li>
                                        <li className="pb-3"><strong>90 Minutes</strong></li>
                                    </ul>
                                    <ul className="mt-2 NoDot">
                                        <h3 className="pt-4"><a id="Manicure">Manicure & Pedicure</a></h3>
                                        <li className="pb-3"><strong>Basic Manicure (Natural Nail)**</strong></li>
                                        <li className="pb-3"><strong>Gel Manicure (Natural Nail)**</strong></li>
                                        <li className="pb-3"><strong>Pedicure**</strong></li>
                                        <li className="pb-2"><strong>Gel Pedicure**</strong></li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul className="Hair NoDot">
                                        <h3 className="pt-4"><a id="HairCare">Hair Care</a></h3>
                                        <h5><u>Ladies</u></h5>
                                        <li className="pb-3"><strong>Shampoo, Cut & Style</strong></li>
                                        <li className="pb-3"><strong>Shampoo & Cut</strong></li>
                                        <li className="pb-3"><strong>Shampoo & Style</strong></li>
                                        <li className="pb-3"><strong>Thermal Style</strong></li>
                                        <li className="pb-3"><strong>Highlights:</strong></li>
                                        <li className="pb-3 pl-5"><strong>Partial Highlights</strong></li>
                                        <li className="pb-3 pl-5"><strong>Full Highlights</strong></li>
                                        <li className="pb-3 pl-5"><strong>Framing Highlights</strong></li>
                                        <li className="pb-3"><strong>Color:</strong></li>
                                        <li className="pb-3 pl-5"><strong>Root Retouch</strong></li>
                                        <li className="pb-3 pl-5"><strong>Full Color</strong></li>
                                        <li className="pb-3 pl-5"><strong>Semi Permanent</strong></li>
                                        <li className="pb-3 pl-5"><strong>Brow Tint</strong></li>
                                        <li className="pb-3 pl-5"><strong>Lash Tint</strong></li>
                                        <li className="pb-3"><strong>Brow Shaping</strong></li>
                                    </ul>
                                    {/* <img className="img-fluid rounded pb-4 pt-2" src={require('./Toes.jpg')} alt="" /> */}
                                </div>
                            </row>
                        </div>
                        <div className="col-lg-4 mb-4 pt-4">
                            <TimeContent />

                            <ul className="NoDot MenTop">
                                    <h5><u>Men</u></h5>
                                    <li className="pb-3"><strong>Shampoo & Cut</strong></li>
                                    <li className="pb-3"><strong>Grey Blending</strong></li>
                                    <li className="pb-3"><strong>Highlights</strong></li>
                                    <li className="pb-3"><strong>Grey Blending & Cut</strong></li>
                                    <li className="pb-3"><strong>Customized Pamper Package</strong></li>
                                    <h5><u>Treatments</u></h5>
                                    <li className="pb-3"><strong>Chemistry Shot	</strong></li>
                                    <li className="pb-3"><strong>Deep Treatment Mask</strong></li>
                                    <h5><u>Specialty Services By Appointment</u></h5>
                                    <li className="pb-3"><strong>Corrective Color</strong></li>
                                    <li className="pb-3"><strong>Human Hair Extensions</strong></li>
                                    <li className="pb-3"><strong>Straighteners</strong></li>
                                    <li className="pb-3"><strong>Permanent or Semi</strong></li>
                                    <li className="pb-3"><strong>Special Occasion Up-Do’s</strong></li>            
                            </ul>
                        </div>
                        
                        <ul className="NoDot">
                            <li className=""><small>** additional charge for French or American Paint style</small></li>
                            <li className=""><small>* Pricing is base rate for service, final charges may increase depending on additional services or time required for procedure.</small></li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Services;