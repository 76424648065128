import React, { Component } from 'react';
import './App.css';
import Menu from './Menu';
import Footer from './Footer';
import Time from './Time';

class Products extends Component {
    render() {
        return (
            <div className="bg">
                <div className="" >
                    <Menu />
                </div>
                <div className="container mt">
                    <div className="row">
                        <div className="col-8">
                            <ul className="mt-4 NoDot">
                                <h2>Products</h2>
                                <h3>Jane Iredale®</h3>
                                <li className="pb-4"><strong>The most beautiful cosmetic you can wear is a healthy skin. That’s why everything we develop is a true extension of skin care. Jane Iredale® makeup is not just a refinement of conventional makeup; it’s a new technology. It’s so effective it is recommended by plastic surgeons, dermatologists and skin care professionals.</strong></li>
                                <li className="pb-5"><strong>Gegee’s Studio is proud to offer Jane Iredale® to our clients. As expert Jane Iredale® specialists, we strive to deliver the best products and services in the Lake City, FL area. Our decision to offer Jane Iredale® to our valued customers is part of this commitment.</strong></li>
                                <h3>IMAGE Skincare</h3>
                                <li className="pb-4"><strong>Beautiful skin starts with healthy skin. To maintain and improve skin health, an effective, professional pharmaceutical skincare regimen is essential.</strong></li>
                                <li className="pb-5"><strong>Over-the-counter products only treat skin’s surface layers. Optimal skin health is achieved by reaching live tissue- IMAGE Skincare professional products work at the cellular level to diminish fine lines, fade sun damage, increase hydration and stimulate cellular turnover for more youthful looking skin.</strong></li>
                                <h3>Footlogix</h3>
                                <li className="pb-4"><strong>Footlogix is the world’s first and only PEDICEUTICAL® Mousse Foot Care line – made with pharmaceutical grade ingredients – developed by Katharin von Gavel, one of North America’s leading authorities and true pioneers in Pedi-CARE.</strong></li>
                                <li className="pb-4"><strong>Scientifically formulated, Footlogix’s unique groundbreaking Dermal Infusion Technology® enables active ingredients to penetrate faster and deeper into troubled skin of the feet. With results seen in a mere few days, from curing simple dryness to addressing more severe conditions resulting from Diabetes, Footlogix has been clinically proven to perform.</strong></li>
                                <li className="pb-5"><strong>Trusted by millions of feet in over 20 countries, Footlogix Pediceuticals® have provided the healing solutions demanded by renowned professionals and their clients around the globe!</strong></li>
                                <h3>Shellac®</h3>
                                <li className="pb-5"><strong>Gegee’s Studio is proud to offer Shellac® to our clients. As expert Shellac® specialists, we strive to deliver the best products and services in the Lake City, FL area. Our decision to offer Shellac® to our valued customers is part of this commitment.</strong></li>
                                <h3>Vi Aesthetics®</h3>
                                <li className="pb-5"><strong>Vi Aesthetics® is the creator of the first painless chemical peel. Providing a full portfolio of powerful professional treatments to experience at GeGee’s Studio, as well as a comprehensive skincare line for patients to enhance and extend the results or simply optimize their skin health.</strong></li>
                            </ul>
                        </div>
                        <Time />
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Products;