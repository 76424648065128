import React, { Component } from 'react';
import './App.css';
import Menu from './Menu';
import Footer from './Footer';
import Time from './Time';

class Memberships extends Component {
    render() {
        return (
            <div className="bg">
                <Menu />
                <div>
                    <div className="container mt">
                        <div className="row">
                            <div className="col-8">
                                <ul className="mt-4">
                                    <h2>Memberships</h2>
                                    <h4 className="pb-5"><strong>Enjoy great savings and membership discounts on treatments and products with the following monthly membership programs, designed to offer affordable maintenance.</strong></h4>
                                    <li className="pb-4"><strong>All packages are annual, after which time they roll into a month to month contract. In order to cancel at that time or, we require a 2 week written notice. Packages are for monthly services and do not roll over if not used for any given month.</strong></li>
                                    <li className="pb-5"><strong>A one time start up fee of $49 is required to set up each membership. As our gift to each new member, you will receive a free Eye Treatment with the Skin Care packages and a free Mini Facial. These may be used at any time during your membership.</strong></li>
                                    <h2><strong>Facials</strong></h2>
                                    <h3 className="pt-2"><strong>Facial Plus</strong></h3>
                                    <h5>$70/mo</h5>
                                    <li className="pb-3"><strong>One Facial a month (PERK Hydrating Facial, Vital C Facial, Rosacea Facial, MD Crystal Microdermabrasion Facial, Dermaplaning Facial)</strong></li>
                                    <li className="pb-3"><strong>10% off any products</strong></li>
                                    <li className="pb-3"><strong>$19 Upgrade to Hydro Microdermabrasion Oxygen Infusion Treatment or Paraffin Mask Treatment</strong></li>

                                    <h3><strong>Elite Skin Membership</strong></h3>
                                    <h5>$199/mo</h5>
                                    <li className="pb-3"><strong>Receive your choice of One Microchanneling, Hydro Microdermabrasion Oxygen Infusion Treatment each month.</strong></li>
                                    <li className="pb-3"><strong>10% off all retail products.</strong></li>

                                    <h3><strong>Vi Peel Precision Package</strong></h3>
                                    <h5>$600/for 3 sessions</h5>
                                    <li className="pb-3"><strong>Receive three VI Peel sessions</strong></li>
                                    <li className="pb-3"><strong>10% off all retail products.</strong></li>
                                    <li className="pb-3"><strong>Include: hydro microdermabrasion (10 Days after peel)</strong></li>
                                    <li className="pb-3"><strong>Take home kit of product essential products for your treatments success, ensuring optimal and lasting results</strong></li>
                                    <li className="pb-3"><strong>If you choose microchanneling (21-28 Days after peel) + $100</strong></li>

                                    <h3><strong>Vi Peel Precision Plus Package</strong></h3>
                                    <h5>$750/for 3 sessions</h5>
                                    <li className="pb-3"><strong>Receive three VI Peel sessions</strong></li>
                                    <li className="pb-3"><strong>10% off all retail products.</strong></li>
                                    <li className="pb-3"><strong>Include: hydro microdermabrasion(10 Days after peel)</strong></li>
                                    <li className="pb-3"><strong>Take home kit of product essential products for your treatments success, ensuring optimal and lasting results</strong></li>
                                    <li className="pb-3"><strong>If you choose microchanneling its (21-28 Days after peel) + $100</strong></li>
                                    <li className="pb-5"><strong>The Precision Plus package adds a powerful booster system targeted to treat stubborn pigmentation, including melasma</strong></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}


export default Memberships;