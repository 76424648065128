import React, { Component } from 'react';
import './App.css';
import Menu from './Menu';
import Footer from './Footer';
import Time from './Time';
import iframe from 'react-iframe';

class Contact extends Component {
    render() {
        return (
            <div className="bg">
                <div className="">
                    <Menu />
                </div>
                <div className="container mt mb-35">
                    <div className="row">
                        <div className="col-8">
                            <ul className="mt-4 NoDot">
                                <h2>Contact Us</h2>
                                <li className="pb-1"><strong>Gegee’s Studio</strong></li>
                                <li className="pb-1"><strong>440 East Duval St</strong></li>
                                <li className="pb-1"><strong>Lake City, FL 32055</strong></li>
                                <li className="pb-1"><strong>Email Address: karen@gegees.com</strong></li>
                                <li className="pb-4"><strong>386-758-2088</strong></li>
                            </ul>
                            <iframe className="mb-5 google" frameborder="0" src={"https://www.google.com/maps/embed/v1/place?q=place_id:ChIJFcx-8HM574gR0Ps2h7-fZkY&key=AIzaSyB1SfoHukc1WKx-tiX8VeZDilnspYaBnyo"} allowfullscreen></iframe>
                        </div>
                        <Time />
                    </div>
                </div>
                <div className="">
                <Footer />
                </div>
                
            </div>
        );
    }
}

export default Contact;