import React, { Component } from 'react';
import './App.css';
import Services from './Services';
import HairCare from './HairCare';
import Contact from './Contact';
import Manipedi from './Manipedi';

class Footer extends Component {
    render() {
        return (
            <footer className="nav-bar pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <h5 className="text-white">Services:</h5>
                            <div>
                                <a className="text-white-50" href="/Services#SkinCare">Skin Care (Facial)</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Services#SpaPackages">Spa Packages</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Services#MakeUp">Make up</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Services#HairRemoval">Hair Removal</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Services#Massage">Massage</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Services#Manicure">Manicure & Pedicure</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Services#HairCare">Hair Care</a>
                            </div>
                        </div>
                        <div className="col-3">
                            <h5 className="text-white">Products</h5>
                            <div>
                                <a className="text-white-50" href="/Products">Jane Iredale</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Products">IMAGE Skincare</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Products">Footlogix</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Products">Shellac</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Products">VI Aesthetics</a>
                            </div>
                        </div>
                        <div className="col-3">
                            <h5 className="text-white">About:</h5>
                            <div>
                                <a className="text-white-50" href="/About">About Gegee's</a>
                            </div>
                            <div>
                                <a className="text-white-50" href="/Contact">Contact Us</a>
                            </div>
                        </div>
                        <ul className="col-3">
                            <h5 className="text-white" >Contact Info:</h5>
                            <div className="text-white-50" >Gegee's Studio</div>
                            <div className="text-white-50" >440 East Duval St</div>
                            <div className="text-white-50" >Lake City, FL 32055</div>
                            <div className="text-white-50" >Phone Number: 386-758-2088</div>
                            <div className="text-white-50" >Email: karen@gegees.com</div>
                        </ul>
                    </div>
                    <div className="row text-center">
                        <p className="text-white col-md">Copyright &copy; Gegee's Studio 2019</p>
                    </div>
                </div>
            </footer>
        )
    }
}


export default Footer;