import React, { Component } from 'react';
import './App.css';
import Menu from './Menu';
import Footer from './Footer';

class Appointment extends Component {
    render() {
        return (
            <div>
                <div className="">
                    <Menu />
                </div>
                <div className="fixed-bottom">
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Appointment;